@import "../../styles/utils/variables";


.page_ctn {
    color: $primary-color;
    font-size: 2em;
    text-align: center;
    margin-left: -25%;
    padding-top: 100px;
    @media (max-width: 1024px) {
        font-size: 28px;
        margin-left: 0;
        // padding: 0;
        // padding-bottom: 260px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
        margin-left: 0;
        // padding: 0;
        padding-bottom: 160px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }

    h1 {
        @media (max-width: 768px) {
            padding-bottom: 24px;
            font-size: 32px;
        }
        @media (max-width: 480px) {
            font-size: 24px;
        }
    }
}