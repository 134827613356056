@import "./styles/utils/variables.scss";
@import "./components/Layout/layout.scss";
@import "./pages/Home/home.scss";
@import "./components/Banner/banner.scss";
@import "./components/CategoryCard/categoryCard.scss";
@import "./components/Header/header.scss";
@import "./components/Navbar/navbar.scss";
@import "./components/Footer/footer.scss";
@import "./components/Modal/modal.scss";
@import "./components/LoginForm/loginForm.scss";
@import "./pages/Contact/contact.scss";
@import "./components/ContactForm/contactForm.scss";
@import "./pages/Products/products.scss";
@import "./components/ProductCard/productCard.scss";
@import "./components/SlideshowModal/slideshowModal.scss";
@import "./components/Slideshow/slideshow.scss";
@import "./components/AdminLayout/adminLayout.scss";
@import "./pages/Admin/admin.scss";
@import "./pages/AdminCategories/adminCategories.scss";
@import "./pages/AdminProducts/adminProducts.scss";
@import "./components/NewCategoryForm/newCategoryForm.scss";
@import "./components/EditModal/editModal.scss";
@import "./components/EditCategoryForm/editCategoryForm.scss";
@import "./components/EditProductForm/editProductForm.scss";
@import "./components/NewProductForm/newProductForm.scss";
@import "./pages/Error/error.scss";
@import "./components/LegalContent/legalContent.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Allura;
}

#root {
    height: 100%;
    width: 100%;
    min-width: 320px;
}


