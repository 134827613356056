
@import "../../styles/utils/variables.scss";


.error {
  text-align: center;
  background-color: $img-color2;

  &__title {
    font-size: 288px;
    margin: 100px auto 30px;
    color: $secondary-color;
    @media (max-width: 1024px) {
      font-size: 180px;
    }

    @media (max-width: 768px) {
      font-size: 96px;
      margin: 55px auto;
    }
  }

  &__txt {
    font-size: 36px;
    color: $primary-color;
    padding-bottom: 36px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  &__link {
    color: $img-color3;
    font-family: Roboto;
    font-size: 18px;
    text-decoration-line: underline;
    margin: 100px auto;

    @media (max-width: 768px) {
      font-size: 14px;
      margin: 75px auto;
    }
  }
}
