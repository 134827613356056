@import "../../styles/utils/variables.scss";


.edit-category-form-wrapper {
  width: 80%;
  margin: auto;
  background-color: $img-color2;
  border-radius: 10px;
  text-align: center;
  padding: 20px;

  @media (max-width: 768px) {
    width: 95%;
    padding: 20px 0;
  }

  .img-edition {
    position: relative;
    padding-bottom: 26px;

    .img_to-edit {
      width: 300px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;

      @media (max-width: 768px) {
        width: 240px;
        height: 160px;
      }

      @media (max-width: 480px) {
        width: 180px;
        height: 120px;
      }
    }

    .img_edit-btn {
      position: absolute;
      padding: 6px;
      margin-bottom: 16px;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      background-color: $img-color2;
      border-radius: 10px 10px 0 0;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }

    .img_edit-input {
      display: none;
    }
  }


  .form-item {
    position: relative;

    label,
    input,
    textarea {
      font-family: "roboto";
      color: $img-color3;
    }

    label {
      position: absolute;
      top: 16px;
      left: 12%;
      color: $img-color3;
      font-size: 14px;
      pointer-events: none;
      user-select: none;

      @media (max-width: 480px) {
        font-size: 12px;
        top: 10px;
      }
    }

    input,
    textarea {
      width: 80%;
      outline: 0;
      border: 1px solid $img-color3;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 12px;
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 12px;
        padding: 8px;
      }
    }

    input:focus+label,
    input:valid+label,
    textarea:focus+label,
    textarea:valid+label {
      font-size: 14px;
      color: $primary-color;
      top: -20px;
      transition: all 0.225s ease;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }

  .submit-btn,
  .delete-btn {
    background-color: $img-color3;
    filter: drop-shadow(2px 2px 3px #0003);
    color: $img-color2;
    font-family: "roboto";
    font-size: 16px;
    display: block;
    padding: 12px 20px;
    margin: 8px auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    @media (max-width : 768px) {
      font-size: 14px;
      padding: 10px 18px;
    }
    @media (max-width : 480px) {
      padding: 8px 16px;
    }

    &:hover {
      transform: scale(1.1, 1.1);
    }

    &:active {
      transform: scale(1.1, 1.1);
      filter: sepia(0.5);
    }

    &.modified {
      background-color: green;
      color: white;
    }

    &.deleted {
      background-color: red;
      color: white;
    }

  }

  .error-message {
    font-size: 20px;
    color: $primary-color;
    padding-top: 16px;
    animation: fadeInGrow 0.5s ease-in-out;
    @media (max-width : 480px) {
      font-size: 16px;
    }
  }

  @keyframes fadeInGrow {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }

    50% {
      opacity: 1;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}