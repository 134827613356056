@import "../../styles/utils/variables.scss";

.footer {
    background-color: $img-color2;
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    text-align: center;

    & .sign {
           color: $primary-color;
           font-weight: 600;
           font-size: 1.2em;
           list-style-type: none;
           padding: 8px 24px 8px 12px;
           @media (max-width : 768px) {
            font-size: 0.9em;
            font-weight: 400;
            padding: 8px 12px 8px 6px;
            text-align: center;
           }
           @media (max-width : 480px) {
            font-size: 0.6em;
           }
        }
}