
.slideshow {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    // @media (max-width : 480px) {
    //     width: 90%;
    // }

    &__img {
        width: 100%;
        height: 100%;
        // position: absolute;
        object-fit: cover;
        // @media (max-width : 480px) {
        //     width: 80%;
        // }
    }

    &__arrow {
        position: absolute;
        cursor: pointer;
        width : 40px;
    }

    .arrow_left {
        left: 10px;
        // top: 0;
        bottom: 20px;
        margin: auto;
    }

    .arrow_right {
        right: 10px;
        // top: 0;
        bottom: 20px;
        margin: auto;
    }
}