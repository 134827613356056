@import '../../styles/utils/variables';


.card {
    width: 400px;
    height: 280px;
    background: white;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0;
    transform: scale(0.95);
    transition: box-shadow 0.4s, transform 0.4s;

    @media (max-width : 768px) {
        width: 280px;
        height: 196px;
    }

    @media (max-width : 480px) {
        width: 200px;
        height: 140px;
    }


    &:hover {
        transform: scale(1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
    }

    &__content {
        width: 100%;
        height: 100%;

        &__cover {
            height: 80%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            @media (max-width : 768px) {
                height: 78%;
            }
        }

        &__loupe {
            background-color: $img-color2;
            border-radius: 10px;
            position: absolute;
            left: 5px;
            top: 5px;
            cursor: pointer;
            opacity: 0.7;
            z-index: 2;
            width: 40px;

            @media (max-width : 768px) {
                width: 32px;
            }

            @media (max-width : 480px) {
                width: 24px;
            }
        }

        &__bottom {
            height: 20%;

            @media (max-width : 768px) {
                height: 22%;
            }

            h1,
            p {
                margin: 0;
                padding-left: 8px;

            }

            h1 {
                font-weight: 600;
                font-size: 1.6em;

                @media (max-width : 768px) {
                    font-size: 1.2em;
                }

                @media (max-width : 480px) {
                    font-size: 0.9em;
                }
            }

            p {
                font-size: 1.2em;
                font-weight: 600;

                @media (max-width : 768px) {
                    font-size: 0.9em;
                }
                @media (max-width : 480px) {
                    font-size: 0.7em;
                }
            }
        }

    }

    &__inside {
        z-index: 1;
        background: $secondary-color;
        width: 105px;
        height: 105px;
        position: absolute;
        top: -50px;
        right: -50px;
        border-radius: 0px 0px 200px 200px;
        transition: all 0.5s, border-radius 1.2s, top 1s;
        @media (max-width : 768px) {
            width: 95px;
            height: 95px;
            border-radius: 0px 0px 300px 200px;
        }

        @media (max-width : 480px) {
            width: 85px;
            height: 85px;
            border-radius: 0px 0px 400px 200px;
        }


        &:hover {
            width: 100%;
            right: 0;
            top: 0;
            border-radius: 0;
            height: 80%;
            overflow: auto;
            scrollbar-color: $secondary-color $img-color2;
            @media (max-width : 768px) {
                height: 78%;
            }

            .card__inside__icon {
                opacity: 0;
            }

            .card__inside__contents {
                opacity: 1;
                transform: scale(1) translateY(0);
            }
        }

        &__icon {
            position: absolute;
            right: 60px;
            top: 60px;
            opacity: 1;
            width: 20px;
            @media (max-width : 768px) {
                width: 18px;
                right: 58px;
                top: 58px;
            }

            @media (max-width : 480px) {
                width: 15px;
            }
        }

        &__contents {
            padding: 5%;
            opacity: 0;
            transform: scale(0.5);
            transition: opacity 0.2s, transform 0.8s;

            h2,
            p {
                color: $primary-color;
                margin-top: 0;
            }

            h2 {
                text-align: center;
                font-size: 32px;
                @media (max-width : 768px) {
                    font-size: 1.6em;
                    padding-bottom: 8px;
                }
                @media (max-width : 480px) {
                    font-size: 1.2em;
                    padding-bottom: 8px;
                }

            }

            p {
                font-size: 24px;
                @media (max-width : 768px) {
                    font-size: 1.3em;
                }
                @media (max-width : 480px) {
                    font-size: 1.1em;
                }
            }
        }
    }
}