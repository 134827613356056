@import "../../styles/utils/variables.scss";

.header {
  width: 100%;

  & .navbars {
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  & p {
    color: $primary-color;
    font-weight: 600;
    font-size: 1.4em;
    transition: 0.5s ease;
    @media (max-width : 768px) {
      font-size: 1.1em;
      font-weight: 400;
    }
    @media (max-width : 480px) {
      font-size: 0.8em;
    }

    &:hover {
      color: $img-color3;
      transform: translate(4px, 6px);
    }
  }

  & .admin {
    text-align: start;
    padding-top: 8px;
    cursor: pointer;
  }

  & .logged_links {
    display: flex;
  }

  & .modal_link {
    padding-right: 48px;
    @media (max-width : 768px) {
      padding-right : 24px;
    }
  }

}