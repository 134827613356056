@import "../../styles/utils/variables";

.legalContent {
    padding: 24px 0;

    h3,
    h4,
    p {
        font-family: "roboto";
    }

    h2 {
        font-size : 32px;
        color: $primary-color;
        padding-bottom: 12px;
        @media (max-width : 768px) {
            font-size: 26px;
        }
    }

    h3 {
        @media (max-width : 480px) {
            font-size: 16px;
        }
    }

    h3,
    h4 {
        color: $secondary-color;
        padding: 8px;
    }

    p {
        padding: 8px;

        @media (max-width : 768px) {
            font-size: 14px;
        }
    }
}