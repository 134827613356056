@import "../../styles/utils/variables.scss";

.main-content {
    width: 100%;
}

.nav_logo {
    text-align: center;
    padding: 16px 0 22px;
    & img{
        @media (max-width : 1024px){
            width : 150px;
        }
        @media (max-width : 768px){
            width : 120px;
        }
        @media (max-width : 480px){
            width : 70px;
        }
    }
}

.main__categories {
    display: flex;
    width: 80%;
    flex-direction: column;
    margin: auto;

    &__title {
        font-size: 56px;
        text-align: center;
        padding-top: 48px;
        color: $primary-color;
        @media (max-width : 768px) {
            font-size: 32px;
        }
        @media (max-width : 480px) {
            font-size: 20px;
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        border-radius: 25px;
        gap: 50px;
        padding: 40px;
        justify-content: center;
        @media (max-width : 768px) {
            width : 100%;
            gap: 25px;
            padding: 28px;
        }
    }
}