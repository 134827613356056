@import "../../styles/utils/variables";

.main-container {
background-color: $img-color2;
padding-top: 6px;
display: flex;
flex-direction: column;
    &__cnt {
        min-height: 100vh;
    }
}