@import "../../styles/utils/variables.scss";

.admin-product-form-wrapper {
    width: 80%;
    margin: auto;
    background-color: $img-color2;
    border-radius: 10px;
    text-align: center;
    padding: 20px;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }

    .form-item,
    .form-selector {
        position: relative;
        padding-bottom: 12px;
        @media (max-width: 768px) {
            padding-bottom: 8px;
        }

        label,
        input,
        textarea,
        #category-select,
        option {
            font-family: "roboto";
            color: $img-color3;
        }

        label {
            position: absolute;
            top: 12px;
            left: 12%;
            color: $img-color3;
            font-size: 14px;
            pointer-events: none;
            user-select: none;

            @media (max-width: 768px) {
                left: 8%;
            }

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }

        .file-label {
            position: absolute;
            top: -24px;
        }

        input,
        textarea,
        #category-select {
            width: 80%;
            outline: 0;
            border: 1px solid $img-color3;
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 12px;
            font-size: 16px;

            @media (max-width: 768px) {
                width: 90%;
            }

            @media (max-width: 480px) {
                font-size: 14px;
                margin-bottom: 14px;
                padding: 8px;
            }

        }

        input:focus+label,
        input:valid+label,
        textarea:focus+label,
        textarea:valid+label {
            font-size: 14px;
            color: $primary-color;
            top: -20px;
            transition: all 0.225s ease;

            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }

    .form-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .addFile-button {
            margin: 12px 0;
          }

        .file-label {
            position: absolute;
            top: -28px;
            padding: 8px 0;
        }

        .file-preview {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 8px;

            img {
                width: 55px;
                height: 55px;
                object-fit: cover;
                border: 1px solid $img-color3;
                border-radius: 4px;
            }

            .remove-button {
                background-color: transparent;
                border: none;
                color: $img-color3;
                cursor: pointer;
                font-size: 16px;
                display: flex;
                align-items: center;
                gap: 5px;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }

    .form-selector{
        @media (max-width: 768px) {
            margin-top: -20px;
        }
    }

    #category-select {
        text-align: right;

        @media (max-width:768px) {
            margin-top: 30px;
            text-align: left;
        }
    }

    .submit-btn {
        background-color: $img-color3;
        filter: drop-shadow(2px 2px 3px #0003);
        color: $img-color2;
        font-family: "roboto";
        font-size: 16px;
        display: block;
        padding: 12px 20px;
        margin: 2px auto;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;

        @media (max-width : 768px) {
            font-size: 14px;
            padding: 10px 18px;
        }

        @media (max-width : 480px) {
            padding: 8px 16px;
        }

        &:hover {
            transform: scale(1.1, 1.1);
        }

        &:active {
            transform: scale(1.1, 1.1);
            filter: sepia(0.5);
        }
    }
}