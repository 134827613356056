@import "../../styles/utils/variables";

.contact {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-bottom: 30px;
  padding-top: 30px;
  justify-content: center;

  @media (max-width: 480px) {
    width: 95%;
  }

  .contact_links,
  .contact_form_wrapper {
    width: 46%;
    padding: 5% 2% 8% 2%;

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &_links {
    border-radius: 10px 0 0 10px;

    &_title {
      color: $primary-color;
      letter-spacing: 2px;
      text-align: center;
      font-size: 30px;
      @media (max-width: 1024px) {
        font-size: 26px;
      }

      @media (max-width: 768px) {
        font-size: 24px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }

    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 10px;


      @media (max-width: 480px) {
        padding-top: 20px;
      }

      .link {
        margin: 10px;
        cursor: pointer;
        padding: 0 8px;

        img {
          width: 40px;
          height: 40px;
          transition: 0.3s;
          user-select: none;
          background-color: $img-color2;
          border-radius: 30px;
          opacity: 0.8;

          @media (max-width: 480px) {
            width: 32px;
            height: 32px;
          }
        }

        img:hover {
          transform: scale(1.1, 1.1);
        }

        img:active {
          transform: scale(1.1, 1.1);
          filter: hue-rotate(220deg) drop-shadow(2px 4px 4px #222) sepia(0.3);
        }
      }
    }

    @media (max-width: 480px) {
      border-radius: 10px 10px 0 0;
    }
  }

  &_form_wrapper {
    border-radius: 0 10px 10px 0;

    @media (max-width: 480px) {
      border-radius: 0 0 10px 10px;
    }

    .form_item {
      position: relative;

      label,
      input,
      textarea {
        font-family: "roboto";
      }

      label {
        position: absolute;
        top: 10px;
        left: 2%;
        color: $img-color3;
        font-size: 14px;
        pointer-events: none;
        user-select: none;

        @media (max-width: 480px) {
          font-size: 12px;
        }
      }

      input,
      textarea {
        width: 100%;
        outline: 0;
        border: 1px solid $img-color3;
        border-radius: 4px;
        margin-bottom: 30px;
        padding: 12px;
        font-size: 16px;
        @media (max-width: 1024px) {
          padding: 8px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
        }
      }

      input:focus+label,
      input:valid+label,
      textarea:focus+label,
      textarea:valid+label {
        font-size: 14px;
        color: $primary-color;
        top: -20px;
        transition: all 0.225s ease;
      }
    }

    .submit_btn {
      background-color: $img-color3;
      filter: drop-shadow(2px 2px 3px #0003);
      color: $img-color2;
      font-family: "roboto";
      font-size: 16px;
      display: block;
      padding: 12px 20px;
      margin: 2px auto;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;

      @media (max-width: 480px) {
        font-size: 14px;
        padding: 10px 16px;
      }

      &:hover {
        transform: scale(1.1, 1.1);
      }

      &:active {
        transform: scale(1.1, 1.1);
        filter: sepia(0.5);
      }
    }
  }
}