@import "../../styles/utils/variables";

.layout {
    display: flex;
    width: 100%;
    min-height: 100vh;

    &_aside {
        width: 25%;
        padding-top: 64px;
        margin-top: 20px;
        // z-index: 0;
        background-color: $secondary-color;

        &_sticky-container {
            position: sticky;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & p {
            color: $primary-color;
            font-size: 1.6em;
            font-weight: 600;
            text-align: center;
            padding-top: 32px;
            opacity: 0.7;
            transition: all 0.3s ease;
            transform: scale(1);

            @media (max-width: 768px) {
                font-size: 20px;
                padding: 20px 6px;
            }

            @media (max-width: 480px) {
                font-size: 16px;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.1);
            }
        }

        & a {
            text-decoration: none;
        }
    }

    &_content {
        width: 75%;
        // margin: auto;
        padding: 20px;
    }
}