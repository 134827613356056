@import "../../styles/utils/variables";


.categories__part {
    padding-bottom: 16px;

    &-container {
        width: 100%;
        background-color: $img-color2;
        margin: auto;
        padding: 16px;
        margin-top: 16px;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 1280px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
            margin-left: 20%;
        }

        @media (max-width: 768px) {
            margin-left: 20%;
        }

        @media (max-width: 480px) {
            margin-left: 8%;
        }
    }
}

.categories__add-title {
    color: $primary-color;
    font-size: 2em;
    text-align: center;
    padding-top: 36px;
    @media (max-width: 768px) {
        font-size: 28px; 
    }
    @media (max-width: 480px) {
        font-size: 24px; 
    }
}