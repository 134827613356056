
@import "../../styles/utils/variables.scss";

.banner {
    height: 350px;
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;

    @media (max-width : 1024px) {
        height: 260px;
    }

    @media (max-width : 768px) {
        height: 170px;
    }
   
    @media (max-width : 480px) {
        height: 111px;
    }


    .banner__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.7);
    }

    .scrollContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap; /* Empêche le texte de passer à la ligne */
        overflow: hidden;
    }

    .banner__txt {
        text-align: center;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin:auto;
        font-size: 64px;
        font-weight: 500;
        color: $img-color2;
        @media (max-width : 1024px) {
            font-size: 50px;
        }

        @media (max-width : 768px) {
            font-size: 36px;
        }

        @media (max-width : 480px) {
            font-size: 24px;
        }
    }
}

