@import '../../styles/utils/variables';

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fond semi-transparent pour l'overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Assure que la modale apparaît au-dessus du reste du contenu */
}

.modalContent {
    max-width: 90%;
    position: absolute;
    background-color: #fff;
    /* Couleur de fond de la modale */
    border-radius: 8px;
    /* Coins arrondis */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Ombre portée */
    padding: 20px;
    /* Espacement intérieur */
    overflow: auto;

    /* Activation du défilement si le contenu est trop long */
    @media (max-width : 480px) {
        padding: 10px;
    }
}

.closeButton {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: $img-color2;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 2px;

    @media (max-width : 768px) {
        width: 30px;
        height: 30px;
        font-size: 20px;
    }

    @media (max-width : 480px) {
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        font-size: 16px;
    }
}