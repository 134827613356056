@import "../../styles/utils/variables.scss";

.contact_ctn {
    width: 75%;
    margin: auto;
    padding-top: 108px;
    @media (max-width : 480px) {
        padding-top: 56px;
    }
    & h1 {
        color: $primary-color;
        font-size: 2.5em;
        text-align: center;
        margin: 0;
        @media (max-width : 768px) {
            font-size : 30px;
        }
        @media (max-width : 480px) {
            font-size : 20px;
        }
    }
}