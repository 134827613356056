@import "../../styles/utils/variables";


.login_form {
  background-color: $img-color2;
  border-radius: 10px;
  text-align: center;
  padding: 40px 20px 20px;

  @media (max-width : 768px) {
    border-radius: 0 0 10px 10px;
    padding: 45px 0 10px;
  }

  .form_item {
    position: relative;

    label,
    input {
      font-family: "roboto";
    }

    label {
      position: absolute;
      top: 10px;
      left: 12%;
      color: $img-color3;
      font-size: 14px;
      pointer-events: none;
      user-select: none;
      @media (max-width : 768px) {
        font-size: 12px;
      }
    }

    input {
      width: 80%;
      outline: 0;
      border: 1px solid $img-color3;
      border-radius: 4px;
      margin-bottom: 25px;
      padding: 12px;
      font-size: 16px;

      @media (max-width : 768px) {
        font-size: 14px;
        padding: 12px 8px;
      }
    }

    input:focus+label,
    input:valid+label {
      font-size: 14px;
      color: $primary-color;
      top: -20px;
      transition: all 0.225s ease;
      @media (max-width : 768px) {
        font-size: 12px;
      }
    }
  }

  .form_check_item {
    padding-bottom: 8px;

    & label {
      font-family: "roboto";
      color: $img-color3;
      font-size: 14px;

      @media (max-width : 768px) {
        font-size: 12px;
      }
    }

    & #rememberMe {
      margin-left: 12px;
    }
  }

  .submit_btn {
    background-color: $img-color3;
    filter: drop-shadow(2px 2px 3px #0003);
    color: $img-color2;
    font-family: "roboto";
    font-size: 16px;
    display: block;
    padding: 12px 20px;
    margin: 2px auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    @media (max-width : 768px) {
      font-size: 14px;
      padding: 10px 18px;
    }
    @media (max-width : 480px) {
      padding: 8px 16px;
    }

    &:hover {
      transform: scale(1.1, 1.1);
    }

    &:active {
      transform: scale(1.1, 1.1);
      filter: sepia(0.5);
    }
  }
}