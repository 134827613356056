@import "../../styles/utils/variables.scss";

.navbar {
    &_list {
        display: flex;
        justify-content: space-evenly;
        padding-right: 40px;
        @media (max-width : 768px) {
            padding-right: 20px;
          }
    }

    &_link {
        font-weight: 600;
        font-size: 1.4em;
        list-style-type: none;
        padding: 8px 12px 8px 24px;
        transition: 0.5s ease;
        @media (max-width : 768px) {
            font-size: 1.1em;
            font-weight: 400;
          }
        @media (max-width : 480px) {
            font-size: 0.8em;
            padding: 8px 6px 8px 12px;
          }

        & a {
            text-decoration: none;
            color: $primary-color;

            &:hover {
                color: $img-color3;
            }
        }

        &:hover {
            transform: translate(4px, 6px);
        }
    }
}