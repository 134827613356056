@import "../../styles/utils/variables";

.products__ctn {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    padding-top: 36px;
    position: relative;
    @media (max-width : 480px) {
        width: 90%;
        padding-top: 24px;
        margin: 0;
    }

    &__title {
        font-size: 56px;
        text-align: center;
        padding-top: 24px;
        color: $primary-color;
        margin-bottom: 48px;
        margin-top: 0;
        @media (max-width : 768px) {
            font-size: 36px;
            margin-bottom: 12px;
        }
        @media (max-width : 480px) {
            font-size: 20px;
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        width: 95%;
        margin: auto;
        border-radius: 25px;
        gap: 50px;
        padding: 40px;
        @media (max-width : 768px) {
            gap: 25px;
        }
    }

    .no-products {
        font-size: 52px;
        text-align: center;
        color: $primary-color;
        margin: auto;
        padding: 76px;
        @media (max-width : 1024px) {
            font-size: 42px;
            padding: 46px;
        }
        @media (max-width : 768px) {
            font-size: 34px;
            padding: 46px;
        }
        @media (max-width : 480px) {
            font-size: 20px;
            padding: 16px;
        }
    }

    .side-nav {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        padding: 16px;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        // z-index: 1000;
        @media (max-width : 1024px) {  
            top: 40%;
        }
        @media (max-width : 768px) {
            padding: 2px;
            right: 0px;
            border-radius: 10px 0 0 10px;
        }
        @media (max-width : 480px) {
            top: 35%;
        }

        &__orderBtn {
            width: max-content;
            text-decoration: none;
            padding: 0 12px;
            border-radius: 5px;
            transition: all 0.4s ease-in-out;
            margin: 10px auto;
            display: flex;
            align-items: center;

            @media (max-width : 1024px) {
                padding: 0;
            }

            & p {
                margin: 12px;
                font-size: 24px;
                @media (max-width : 768px) {
                    font-size: 20px;
                    margin: 10px;
                }
                @media (max-width : 480px) {
                    font-size: 15px;
                    margin: 7px;
                }
            }

            &:hover {
                transform: scale(1.1);
                box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
            }
        }

        &__returnBtn {
            border: 2px solid $secondary-color;
            color: $secondary-color;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            margin: 10px auto;
            border-radius: 5px;
            padding: 0 2px;
            @media (max-width : 768px) {
                width: 30px;
                height: 30px;
                border: 1px solid $secondary-color;
            }
            @media (max-width : 480px) {
                width: 20px;
                height: 20px;
            }

            &__icon {
                width: 32px;
                height: 32px;
                @media (max-width : 768px) {
                    width: 24px;
                    height: 24px;
                }
                @media (max-width : 480px) {
                    width: 14px;
                    height: 14px;
                }
            }

            &:hover {
                transform: scale(1.05);
                box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
            }
        }

        &__orderBtn {
            background-color: $primary-color;
            color: $img-color2;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }
    }
}