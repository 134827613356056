@import "../../styles/utils/variables.scss";

.cat_card {
    margin: 30px;
    cursor: pointer;
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s;
    box-shadow: 0 10px 20px black;

    @media (max-width : 768px) {
        margin: 10px;
        width: 240px;
        height: 160px;
    }

    @media (max-width : 480px) {
        margin: 0px;
        width: 180px;
        height: 120px;
    }

    &:hover {
        transform: scale(1.05);
    }

    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: bottom 0.3s;

        & p {
            font-size: 2.2rem;
            text-align: center;
            padding: 12px;
            @media (max-width : 768px) {
                font-size: 2rem
            }
            @media (max-width : 480px) {
                font-size: 1.4rem
            }
        }
    }

    &:hover .overlay {
        bottom: -100%;
    }
}