@import "../../styles/utils/variables.scss";

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background-color: $img-color2;
    width: 730px;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    animation: scaleIn 300ms ease;
    padding-bottom: 80px;
    @media (max-width: 768px) {
      padding: 10px;
      width: 360px;
    }
    @media (max-width: 480px) {
      width: 220px;
    }
  }

  & .close {
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    width: 30px;
    position: absolute;
    top: 30px;
    right: 36px;
    @media (max-width : 768px) {
      font-size: 16px;
      width: 25px;
      top: 16px;
      right: 18px;
    }
    @media (max-width : 480px) {
      font-size: 12px;
      width: 20px;
    }
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.4);
  }

  100% {
    transform: scale(1);
  }
}